<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";

import * as XLSX from 'xlsx';



/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);

        const doesAddOpenRecord = ref(false);
        const doesEditOpenRecord = ref(false);
        const doesDeleteOpenRecord = ref(false);


        return {
            doesAddOpen,
            doesEditOpen,
            doesDeleteOpen,


            doesAddOpenRecord,
            doesEditOpenRecord,
            doesDeleteOpenRecord,
        };
    },
    page: {
    title: "Projets",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Projet",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Projet",
          active: true,
        },
        {
          text: "Un projet",
          active: true,
        },
      ],
      errors: [],
      id: "",
      name: "",
      description: "",
      weight: 1,
      amount: "",
      date_start: "",
      date_end: "",
      project: {},
      activity: {},
      records: [],
      newRecord: {},
      record: {},
      parameters: [],
      loading: false,
      loadingPublish: false,
      status: 201,
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('sector/projects/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.project = data.data
        this.parameters=this.project.entity.projectform.projectformparameters
        console.log(this.parameters)
      })
    },
    loadRecords() {
      fetch(Helper.route('response_forms'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        this.records = data.data
      })
    },
    publishProject() {
        this.loadingPublish = true
        fetch(Helper.route('sector/projects/'+this.id+'/publish'), Helper.requestOptions())
        .then(response => {
            Helper.tokenChecker(response.status)
            return response.json()
        })
        .then(data => {
            this.loadingPublish = false
            console.log(data);
            if(data.status == false) {
              Swal.fire("Activités non envoyées au CDN !", data.message.join(";"), "error");
            } else {
              this.project = data.data
              Swal.fire("Activités publiées !", "Les activités sont déjà envoyé au CDN", "success");
            }
        })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('project_id', this.id)
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('amount', this.amount)
      //formData.append('weight', this.weight)
      formData.append('date_start', this.date_start)
      formData.append('date_end', this.date_end)
      if(document.getElementById("file").files[0] != undefined) {
        formData.append('file', document.getElementById("file").files[0])
      }
      fetch(Helper.route('sector/activities'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.doesAddOpen = false
          this.initData(null)
          this.project.activities.push(data.data)
          Swal.fire("Activité ajouter !", "L'activité a été enregistrée avec succès.", "success");
        } else {
          this.errors.name = Helper.getError(data.data, "name")
          this.errors.description = Helper.getError(data.data, "description")
          this.errors.amount = Helper.getError(data.data, "amount")
          this.errors.weight = Helper.getError(data.data, "weight")
          this.errors.date_start = Helper.getError(data.data, "date_start")
          this.errors.date_end = Helper.getError(data.data, "date_end")
          this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('project_id', this.id)
      formData.append('name', this.name)
      formData.append('description', this.description)
      formData.append('amount', this.amount)
      //formData.append('weight', this.weight)
      formData.append('date_start', this.date_start)
      formData.append('date_end', this.date_end)
      if(document.getElementById("file1").files[0] != undefined) {
        formData.append('file', document.getElementById("file1").files[0])
      }
      formData.append('_method', "PUT")
      console.log(formData.get("name"), 'id', this.activity.id);
      fetch(Helper.route('sector/activities/'+this.activity.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        this.loading = false
        if (this.status == 200) {
          this.doesEditOpen = false
          this.project.activities = this.project.activities.map(i => i.id !== this.activity.id ? i : data.data)
          Swal.fire("Activité modifier !", "L'activité a été modifiée avec succès.", "success");
        } else {
          this.errors.name = Helper.getError(data.data, "name")
          this.errors.description = Helper.getError(data.data, "description")
          this.errors.amount = Helper.getError(data.data, "amount")
          this.errors.weight = Helper.getError(data.data, "weight")
          this.errors.date_start = Helper.getError(data.data, "date_start")
          this.errors.date_end = Helper.getError(data.data, "date_end")
          this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      console.log(this.activity.id);
      fetch(Helper.route('sector/activities/'+this.activity.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        this.doesDeleteOpen = false
        if (this.status == 204) {
          this.project.activities = this.project.activities.filter(i => i.id !== this.activity.id)
          Swal.fire("Partenaire supprimer !", "Le partenaire a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Partenaire non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
        this.activity = null
      })
    },
    initItem(activity, action="edit") {
        this.activity = activity
        this.initData(activity)
        if (action == "delete") {
          this.doesDeleteOpen = true
        } else {
          this.doesEditOpen = true
        }
    },
    initData(item = null) {
      this.name = item != null ? item.name : ""
      this.amount = item != null ? item.amount : ""
      this.weight = item != null ? item.weight : ""
      this.date_start = item != null ? item.date_start : ""
      this.date_end = item != null ? item.date_end : ""
      this.description = item != null ? item.description : ""
    },



    addItemRecord() {
      this.loading = true
      this.errors = []
      let formData = new FormData()

      this.parameters.forEach(indicator => {
        formData.append(indicator.id, this.newRecord[indicator.id]);
      });
    
      formData.append('project_form_id', this.project.entity.projectform.id)
      formData.append('project_id', this.project.id)
      formData.append('entity_id', this.project.entity.id)
      
      fetch(Helper.route('response_forms'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200 || this.status == 201 || this.status== 204) {
          this.doesAddOpenIndicateur = false
          this.initDataRecord(null)
          this.records.push(data.data)
          Swal.fire("Enregistrement ajouté !", "L'enregistrement a été enregistré avec succès.", "success");
        } else {
          //this.errors.entity_id = Helper.getError(data.data, "entity_id")
          //this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },

    editItemRecord() {
      this.loading = true
      this.errors = []
      let formData = new FormData()

      formData.append('_method', "PUT")

      this.parameters.forEach(indicator => {
        formData.append(indicator.id, this.newRecord[indicator.id]);
      });
    
      formData.append('project_form_id', this.project.entity.projectform.id)


      fetch(Helper.route('response_forms/'+this.record.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log('last',this.record);
        console.log('new',data.data);
        this.loading = false
        if (this.status == 200 || this.status == 201 || this.status== 204) {
          this.name = ""
          this.doesEditOpenRecord = false
          this.records = this.records.map(i => i.id !== this.record.id ? i : data.data)
          Swal.fire("Enregistrement modifier !", "L'enregistrement a été modifié avec succès.", "success");
        } else {
            this.errors.libelle = Helper.getError(data.data, "libelle")
            this.errors.value = Helper.getError(data.data, "value")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },

    deleteItRecord() {
      this.loading = true
      fetch(Helper.route('response_forms/'+this.record.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        this.doesDeleteOpenRecord = false
        if (this.status == 204) {
          this.records = this.records.filter(i => i.id !== this.record.id)
          Swal.fire("Enregistrement supprimer !", "L'enregistrement a été supprimé avec succès.", "success");
        } else {
          Swal.fire("Enregistrement non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
        this.parametre = null
      })
    },

    

    initItemRecord(newRecord , action = "edit") {
      console.log(newRecord);
      this.record=newRecord;
      //this.newRecord = { ...newRecord.response_form_parameters };
      //console.log("you",this.newRecord)
      this.initDataRecord(newRecord.response_form_parameters);
      
      if (action === "delete") {
        this.doesDeleteOpenRecord = true;
      } else {
        this.doesEditOpenRecord = true;
      }
    },



    initDataRecord(item = null) {
      if (item) {
        console.log("order", item);

        this.parameters.forEach((indicator, i) => {
          console.log("value",item[i]["value"])
          this.newRecord[indicator.id] = item[i]["value"] || ''; 
        });
      }
    },


    downloadExcel() {
        // Assuming list_parameters is available in the context
        const list_parameters = this.parameters; // Make sure list_parameters is defined and accessible

        // Create headers based on list_parameters
        const headers = {
            Date: 'Date',
            ...Object.fromEntries(list_parameters.map(param => [param.libelle, param.libelle]))
        };

        // Create data rows
        const dataRows = this.records.map(record => {
            let data = {
                Date: record.created_at,
            };

            // Initialize the rest of the data fields with empty strings
            list_parameters.forEach(param => {
                data[param.libelle] = '';
            });

            // Populate the data fields with actual values
            record.response_form_parameters.forEach(param => {
                const listParam = list_parameters.find(p => p.id === param.project_form_parameter_id);
                if (listParam) {
                    data[listParam.libelle] = param.value;
                }
            });

            return data;
        });

        // Combine headers and data
        const finalData = [headers, ...dataRows];

        // Create worksheet from the final data
        const ws = XLSX.utils.json_to_sheet(finalData, { skipHeader: true });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Données");

        // Create an Excel file
        XLSX.writeFile(wb, "enregistrements.xlsx");
    }


  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
    this.loadRecords();
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.activity = null
        this.initData(null)
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.activity = null
        this.initData(null)
      }
    },


    doesAddOpenRecord: function() {
      this.errors = []
    },
    doesEditOpenRecord: function() {
      this.errors = []
      if (!this.doesEditOpenRecord) {
        this.record = null
        this.initDataRecord(null)
        //this.initDataIndicateur(null)
      }
    },
    doesDeleteOpenRecord: function() {
      if (!this.doesDeleteOpenRecord) {
        this.record = null
        this.initDataRecord(null)
        //this.initDataIndicateur(null)
      }
    }


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    


    <div class="hstack mb-3" v-if="project.activity_is_add == false">
        <div class="ms-auto">
            <div v-if="project.activity_is_add == 0">
                <b-button variant="success" v-if="!loadingPublish" @click="publishProject()" class="ms-auto">
                    <i class="bx bx-send font-size-16 align-middle me-2"></i>
                    Toutes les activités ont été enregistrées
                </b-button>
                <div class="text-center" v-else>
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <b-button variant="primary" class="ms-3" @click="doesAddOpen = true">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter une nouvelle activité
        </b-button>

        <b-button variant="primary" @click="doesAddOpenRecord = true" class="ms-3">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Renseigner les indicateurs climatiques
        </b-button>
        
    </div>


    
    <div class="row" v-if="project.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title text-primary mb-3">{{ project.name }}</h4>
            <p><b>Fichier du projet</b> : 
                <a :href="STORAGE_URL+project.file" v-if="project.file" target="_blank" class="btn btn-sm btn-success rounded-pill">
                    <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                    Télécharger le Fichier
                </a>
            </p>
            <p>
                <b>Progression</b> : {{ project.progress }}%
                <b-progress :value="project.progress" :max="100" class="mt-2" variant="success"></b-progress> 
            </p>
            <p><b>Montant alloué</b> : {{ project.cost }} F CFA</p>
            <p><b>Date debut</b> : {{ project.date_start }}</p>
            <p><b>Date Fin</b> : {{ project.date_end }}</p>
            <h6><b>Description</b></h6>
            <p class="card-title-desc">
              {{ project.description }}
            </p>

            <div class="bg-light p-3 pt-3 mt-3">
                <h6 class="font-20 text-danger">♣ Listes des activités</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Activité</th>
                                <th class="text-center">Progession</th>
                                <th class="text-center">Poids</th>
                                <th class="text-center">Montant alloué</th>
                                <th v-if="project.activity_is_add == false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(activity, i) in project.activities" :key="activity.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                    <router-link v-if="project.activity_is_add" :to="'/sector/activities/'+activity.id">{{ activity.name }}</router-link>
                                    <span v-else>{{ activity.name }}</span>
                                </td>
                                <td class="text-center">{{ activity.progress }} %</td>
                                <td class="text-center">{{ activity.weight }}</td>
                                <td class="text-center text-black">{{ activity.amount }} F</td>
                                <td class="hstack" v-if="project.activity_is_add == false">
                                    <b-button class="btn-sm" @click="initItem(activity)" variant="success">
                                        <i class="bx bx-edit font-size-16 align-middle mx-1"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="initItem(activity, 'delete')" class="ms-2 btn-sm">
                                        <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="bg-light p-3 pt-3 mt-3">
              <b-button variant="primary" @click="downloadExcel" class="mb-3 ms-auto">
                Exporter en Excel
              </b-button>
                <h6 class="font-20 text-danger">♣ Suivi des indicateurs climatiques</h6>

                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th v-for="indicator in project.entity.projectform.projectformparameters" :key="indicator.id">{{ indicator.libelle }}</th>
                            <!-- <th>Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(record, i) in records" :key="record.id">
                            <th scope="row">{{ i + 1 }}</th>
                            <td v-for="indicator in record.response_form_parameters" :key="indicator.id">{{ indicator.value }}</td>
                            <!-- <td class="hstack">
                                    <b-button class="btn-sm" @click="initItemRecord(record, record.response_form_parameters)" variant="success">
                                        <i
                                        class="bx bx-edit font-size-16 align-middle mx-1"
                                        ></i>
                                    </b-button>
                                    <b-button variant="danger" @click="initItemRecord(record, 'delete')" class="ms-2 btn-sm">
                                        <i class="bx bx-trash font-size-16 align-middle mx-1"></i>
                                    </b-button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        centered
        size="lg"
        title="Ajouter une activité"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
            <div class="row">
              <div class="col-md-6 mb-3">
                  <label for="">Nom de l'activité</label>
                  <input type="text" required v-model="name" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
              </div>
              <div class="col-md-6">
                  <label for="">Fichier explicatif (Facultatif)</label>
                  <input type="file" id="file" accept=".pdf, .docx" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.file">{{ errors.file }}</span>
              </div>
              <!-- <div class="col-md-6 mb-3">
                  <label for="">Poids de l'activité (Le cumul des poids doit être = 100)</label>
                  <input type="number" required min="1" max="100" v-model="weight" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.weight">{{ errors.weight }}</span>
              </div> -->
              <div class="col-md-6 mb-3">
                  <label for="">Montant alloué à l'activité</label>
                  <input type="number" required v-model="amount" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.amount">{{ errors.amount }}</span>
              </div>
              <div class="col-md-6 mb-3">
                  <label for="">Date de debut de l'activité</label>
                  <input type="date" required v-model="date_start" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.date_start">{{ errors.date_start }}</span>
              </div>
              <div class="col-md-6 mb-3">
                  <label for="">Date de fin de l'activité</label>
                  <input type="date" required v-model="date_end" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.date_end">{{ errors.date_end }}</span>
              </div>
            <div>
                <label for="">Description de l'activité</label>
                <textarea v-model="description" required class="form-control" rows="4"></textarea>
                <span class="text-danger mt-2" v-if="errors.description">{{ errors.description }}</span>
            </div>
            </div>
            <div class="mt-3" v-if="!loading">
                <input type="submit" value="Enregistrer" class="btn btn-primary">
            </div>
            <div class="mt-3 text-center" v-else>
                <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </form>
    </b-modal>



    <!-- EDIT MODAL -->
    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        centered
        size="lg"
        title="Modifier le sondage"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
          <div class="row">
              <div class="col-md-6 mb-3">
                  <label for="">Nom de l'activité</label>
                  <input type="text" required v-model="name" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.name">{{ errors.name }}</span>
              </div>
              <div class="col-md-6">
                  <label for="">Fichier explicatif (Facultatif)</label>
                  <input type="file" id="file1" accept=".pdf, .docx" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.file">{{ errors.file }}</span>
              </div>
              <!-- <div class="col-md-6 mb-3">
                  <label for="">Poids de l'activité (Le cumul des poids doit être = 100)</label>
                  <input type="number" required min="1" max="100" v-model="weight" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.weight">{{ errors.weight }}</span>
              </div> -->
              <div class="col-md-6 mb-3">
                  <label for="">Montant alloué à l'activité</label>
                  <input type="number" required v-model="amount" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.amount">{{ errors.amount }}</span>
              </div>
              <div class="col-md-6 mb-3">
                  <label for="">Date de debut de l'activité</label>
                  <input type="date" required v-model="date_start" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.date_start">{{ errors.date_start }}</span>
              </div>
              <div class="col-md-6 mb-3">
                  <label for="">Date de fin de l'activité</label>
                  <input type="date" required v-model="date_end" class="form-control">
                  <span class="text-danger mt-2" v-if="errors.date_end">{{ errors.date_end }}</span>
              </div>
            <div>
                <label for="">Description de l'activité</label>
                <textarea v-model="description" required class="form-control" rows="4"></textarea>
                <span class="text-danger mt-2" v-if="errors.description">{{ errors.description }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>
    

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer l'activité"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer l'activité <b>{{ activity?.name }}</b> ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>




     <!-- Response -->


     <!-- Add Record Modal -->

     <b-modal
        v-model="doesAddOpenRecord"
        id="modal-center"
        size="lg"
        centered
        scrollable
        title="Ajouter un enregistrement ff"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItemRecord()">
          <div class="row">

            <div class="mb-3" v-for="indicator in parameters" :key="indicator.id">
                    <label :for="indicator.id">{{ indicator.libelle }}</label>
                    <input v-model="newRecord[indicator.id]" :id="indicator.id" :required="indicator.obligation === 'obligatoire'" :type="indicator.type === 'ALPHANUMERIQUE' ? 'text' : 'number'" class="form-control" />
            </div>
            
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

    <!-- Edit Record Modal -->
    <b-modal
        v-model="doesEditOpenRecord"
        id="modal-center"
        centered
        scrollable
        size="lg"
        title="Modifier l'enregistrement"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItemRecord()">
          <div class="row">

            <div class="mb-3" v-for="indicator in parameters" :key="indicator.id">
                    <label :for="indicator.id">{{ indicator.libelle }}</label>
                    <input v-model="newRecord[indicator.id]" :id="indicator.id" :required="indicator.obligation === 'obligatoire'" :type="indicator.type === 'ALPHANUMERIQUE' ? 'text' : 'number'" class="form-control" />
            </div>

          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>



    

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpenRecord"
        id="modal-center"
        title="Supprimer l'indicateur"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer cette enregistrement ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpenRecord = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteItRecord()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>

  </Layout>
</template>
